/** @jsx jsx */
import { Heading, Text, jsx, Container, Divider } from "theme-ui";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Stack } from "raam";
import MainLayout from "../templates/MainLayout";
import Header from "../components/Header";
import BlogPostCard from "../components/Cards/BlogPostCard";

const BlogPage = ({ location: { pathname } }) => {
  const {
    allWpPost: { edges: posts },
  } = useStaticQuery(graphql`
    {
      allWpPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            categories {
              nodes {
                uri
                name
              }
            }
            customFields {
              subtitle
            }
            date(formatString: "MMM Do, YYYY")
            id
            uri
            title
            excerpt
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      description="All the latest Omne news, straight from the team."
      pathname={pathname}
      title="Blog"
    >
      <Header
        pathname={pathname}
        sx={{ background: "linear-gradient(to right top, #07112b, #142b53)" }}
      />
      <Container px="3" py="8" sx={{ maxWidth: "5xl" }}>
        <Stack gap="3">
          <Heading as="h1" variant="text.heading.two">
            Latest
          </Heading>
          <Text as="p" variant="blog.card.excerpt">
            All the latest Omne news, straight from the team.
          </Text>
        </Stack>
        <Divider my={["4", null, "6"]} variant="divider.light" />
        <Stack gap="8">
          {posts.map((post) => (
            <BlogPostCard key={post.node.id} post={post} />
          ))}
        </Stack>
      </Container>
    </MainLayout>
  );
};

BlogPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default BlogPage;
