/** @jsx jsx */
import { jsx, Card, Heading, Text, Grid } from "theme-ui";
import PropTypes from "prop-types";
import { Stack, Inline } from "raam";
import StyledLink from "../Link.styled";

const BlogPostCard = ({ post }) => (
  <Card key={post.id || post.node.id} as="article" variant="blog">
    <Stack gap="3">
      <Grid
        columns={["1fr", null, "repeat(4, 1fr)"]}
        gap="0"
        sx={{ display: ["block", null, "grid"] }}
      >
        <Inline
          alignItems="flex-start"
          gap="3"
          mb={["2", "0"]}
          mr={[null, null, "4"]}
          sx={{
            gridColumn: [null, null, "1"],
            borderRightStyle: [null, null, "solid"],
            borderRightColor: [null, null, "grey.light"],
            borderRightWidth: [null, null, "1px"],
          }}
        >
          <Text as="time" variant="body.sm">
            {post.node ? post.node.date : post.date}
          </Text>
          {post.node &&
            post.node.categories.nodes.map((category) => (
              <StyledLink
                key={category.name}
                color="blue.mid"
                fontSize={0}
                px="2"
                py="1"
                sx={{
                  backgroundColor: "blue.xlight",
                  borderRadius: "full",
                  lineHeight: "none",
                }}
                textDecoration="none"
                title={category.name}
                to={`/blog${category.uri}`}
              >
                {category.name}
              </StyledLink>
            ))}
        </Inline>
        <StyledLink
          color="blue.xdark"
          sx={{ gridColumn: [null, null, "2 / span 3"] }}
          textDecoration="none"
          to={`/blog${post.node ? post.node.uri : post.uri}`}
        >
          <Heading variant="blog.card.title">
            {post.node ? post.node.title : post.title}
          </Heading>
          {post.node && post.node.customFields ? (
            <Heading as="h3" variant="blog.card.subtitle">
              {post.node.customFields.subtitle}
            </Heading>
          ) : (
            <Heading as="h3" variant="blog.card.subtitle">
              {post.customFields && post.customFields.subtitle}
            </Heading>
          )}
          <Text
            dangerouslySetInnerHTML={{
              __html: post.node ? post.node.excerpt : post.excerpt,
            }}
            mt="2"
            variant="blog.card.excerpt"
          />
        </StyledLink>
      </Grid>
    </Stack>
  </Card>
);

BlogPostCard.propTypes = {
  post: PropTypes.shape({
    customFields: PropTypes.shape({
      subtitle: PropTypes.string,
    }),
    date: PropTypes.string,
    excerpt: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    node: PropTypes.shape({
      categories: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            uri: PropTypes.string,
          })
        ),
      }),
      customFields: PropTypes.shape({
        subtitle: PropTypes.string,
      }),
      date: PropTypes.string,
      excerpt: PropTypes.string,
      id: PropTypes.string,
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
    title: PropTypes.string,
    uri: PropTypes.string,
  }),
};

BlogPostCard.defaultProps = {
  post: {
    node: {
      categories: {
        nodes: {},
      },
      customFields: {
        subtitle: null,
      },
      date: null,
      excerpt: null,
      featuredImage: null,
      id: null,
      title: null,
      uri: "/",
    },
  },
};

export default BlogPostCard;
